import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Transportation Costs",
  "description": "Logistics has its own costs. This is mainly imposed on the transportation costs. What are those, and how can we optimize it?",
  "author": "Ally Cheng",
  "categories": ["shipping-packaging"],
  "date": null,
  "featured": false,
  "tags": ["shipping-packaging"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Transportation cost refers to the sum of the expenses paid to transport goods. This also includes administrative fees and inventory management costs. `}</p>
    <h2 {...{
      "id": "why-are-transportation-costs-important"
    }}>{`Why are transportation costs important?`}</h2>
    <ul>
      <li parentName="ul">{`It is the basis for the calculation of profit and loss of transportation enterprises;`}</li>
      <li parentName="ul">{`Provide a basis for transportation companies to formulate reasonable freight rates;`}</li>
      <li parentName="ul">{`It is an important index for assessing the economic benefits of transportation enterprises;`}</li>
      <li parentName="ul">{`It is an economic index that reflects the level of production, operation and management of transportation enterprises;`}</li>
    </ul>
    <h2 {...{
      "id": "what-are-the-factors-contributing-to-transportation-costs"
    }}>{`What are the factors contributing to transportation costs?`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Composition of freight`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Composition of distribution processing costs`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Composition of loading and unloading costs`}</p>
        <p parentName="li">{`Factors affecting shipping costs:`}</p>
      </li>
    </ul>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Freight transport distance`}</strong>{`: the distance is the main contributing factor of transport costs, meaning it directly affects other variable costs such as labor, fuel, and maintenance fees.`}</li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><strong parentName="li">{`Cargo capacity`}</strong>{`: As with many other logistics activities, there are economies of scale in most transportation activities. Shipping costs per unit weight decrease as the cargo load increases.`}<br parentName="li"></br>
        {`The stowage factor of the cargo, that is, the density, considers the weight and space together, and the transportation cost per unit weight decreases with the increase of the density of the cargo.`}</li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`Transport`}</strong>{` `}<strong parentName="li">{`method's loading capacity`}</strong>{`: refers to the specific size of the product and its impact on the space utilization of the means of transport, and is also affected by the scale of shipment.`}</li>
      <li parentName="ol"><strong parentName="li">{`Handling efficiency`}</strong>{`: Transportation by truck, rail, or ship, etc. may require special handling equipment. In addition, the actual grouping method used for product transportation and storage will also affect the handling cost.`}</li>
      <li parentName="ol"><strong parentName="li">{`Item vulnerability`}</strong>{`: fragile products, there is a risk of damage. `}</li>
      <li parentName="ol"><strong parentName="li">{`Imbalance in transportation demand`}</strong>{`: Market factors such as transportation supply and demand such as transportation channel flow and channel flow balance can affect transportation costs.`}</li>
    </ol>
    <h2 {...{
      "id": "controlling-transportation-cost"
    }}>{`Controlling Transportation Cost`}</h2>
    <p><strong parentName="p">{`Principle`}</strong>{`: The lowest cost, but does not affect reliability, safety, and speed`}</p>
    <p><strong parentName="p">{`Cost composition`}</strong>{`: labor costs, fuel costs, transportation miscellaneous fees, transportation insurance fees, outsourced transportation fees, etc.`}</p>
    <p><strong parentName="p">{`Influencing factors:`}</strong>{` transportation volume, means of transportation, transportation mileage, degree of improvement in loading and unloading technology, transportation rates, etc.`}</p>
    <p>{`Ways to optimize the transportation cost:`}</p>
    <ul>
      <li parentName="ul">{`Choose a reasonable means of transportation; economical, fast, safe, and convenient.`}</li>
      <li parentName="ul">{`Reduce the cost of loading and unloading and minimize the number of times of loading and unloading.`}</li>
      <li parentName="ul">{`Shorten the transportation distance.`}</li>
      <li parentName="ul">{`Choose appropriate operating machinery and operating methods.`}</li>
      <li parentName="ul">{`Strengthen safety production management.`}</li>
      <li parentName="ul">{`Have the proper means of transport`}</li>
      <li parentName="ul">{`Optimize shipping routes`}</li>
      <li parentName="ul">{`Optimize the mode of transportation: `}
        <ul parentName="li">
          <li parentName="ul">{`Strategize for production and sales in different regions`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Optimize warehouse layout`}</li>
      <li parentName="ul">{`Carry out collection and delivery methods: spontaneous collection, planned delivery, and joint delivery`}</li>
      <li parentName="ul">{`Implement a dropshipping strategy`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      